import React, { useState, useCallback } from 'react'
import { Image } from 'react-datocms'
import styled from '@emotion/styled'

import Slider from './Slider'
import Video from './Video'
import media from '../styles/mq'

const Slide = styled.div`
  margin: 0 var(--space-smaller);
`

const Info = styled.div`
  padding: var(--space-medium) 0;
  ${media.greaterThan('medium')} {
    display: flex;
    align-items: flex-start;
  }
  h5 {
    text-transform: uppercase;
    font-size: 1.37rem;
    margin: 0 var(--space-largest) 0 0;
  }
  div {
    color: var(--color-darker);
    font-size: var(--font-size-small);
    ${media.greaterThan('medium')} {
      max-width: 50%;
    }
  }
`

const Collab = ({ name, cover, video, description, playing }) => {
  return (
    <Slide>
      {video ? (
        <Video {...video} playing={playing} autoplay={false} overlay />
      ) : (
        <Image data={cover.fluidFullWidth} />
      )}
      <Info>
        <h5>{name}</h5>
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </Info>
    </Slide>
  )
}

const CollaborationSlider = ({ nodes }) => {
  const [index, setIndex] = useState(0)
  const [playIndex, setPlayIndex] = useState(-1)

  const togglePlayIndex = useCallback(
    (slideIndex) =>
      setPlayIndex((i) => {
        if (i === slideIndex) return -1
        return slideIndex
      }),
    []
  )

  return (
    <Slider
      className="block c-large"
      index={index}
      onIndexChange={setIndex}
      onTap={togglePlayIndex}
      slideAlign="flex-start"
      slideStyle={{ minWidth: '100%' }}
    >
      {nodes.map(({ id, ...rest }, i) => (
        <Collab key={id} {...rest} playing={index === i && playIndex === i} />
      ))}
    </Slider>
  )
}

export default CollaborationSlider
