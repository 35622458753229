import React from 'react'
import { Image } from 'react-datocms'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { NextConcertList } from './ConcertList'

const BandWrapper = styled.div`
  margin-top: var(--space-large);
  margin-bottom: var(--space-large);
  :nth-of-type(even) > div {
    background-color: var(--color-lighter);
  }
  :nth-of-type(odd) > div {
    background-color: var(--color-light);
  }
`

const imageCss = css`
  height: 330px;
`

const bandTitleCss = css`
  padding: var(--space-large) var(--space-larger);
  h3 {
    margin: 0;
  }
  h4 {
    margin-top: var(--space-large);
  }
`

const BandPreview = ({ name, slug, cover, concerts }) => {
  return (
    <BandWrapper className="c-large">
      <Link to={slug}>
        <Image
          data={cover.fluidFullWidth}
          className='fixedImg'
          css={imageCss}
        />
      </Link>
      <div css={bandTitleCss}>
        <Link to={slug}>
          <h3>{name}</h3>
        </Link>
        <NextConcertList concerts={concerts} />
      </div>
    </BandWrapper>
  )
}

export default BandPreview
