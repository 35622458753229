import React, { useMemo } from 'react'
import { graphql } from 'gatsby'
import { useIntl } from 'react-intl'

import BandPreview from '../components/BandPreview'
import CollaborationSlider from '../components/CollaborationSlider'

import { today } from '../utils'

const BandsPage = ({
  data: {
    dato: { page, allBands, allConcerts, allCollaborations }
  }
}) => {
  const { title } = page
  const { formatMessage: t } = useIntl()

  const bandsAndNewConcerts = useMemo(() => {
    const d = today()
    const formattedConcertDates = allConcerts
      .reverse()
      .map(({ date, ...rest }) => ({
        ...rest,
        date: new Date(date)
      }))
    return allBands.map(({ id, ...rest }) => {
      const concerts = formattedConcertDates
        .filter((c) => c.band?.id === id && c.date >= d)
        .slice(0, 2)
      return { id, ...rest, concerts }
    })
  }, [allBands, allConcerts])

  return (
    <>
      <h1 className="c-large">{title}</h1>
      {bandsAndNewConcerts.map(({ id, slug, ...rest }) => (
        <BandPreview key={id} slug={slug} {...rest} />
      ))}
      <h2 className="c-large bar">{t({ id: 'collaborations' })}</h2>
      <CollaborationSlider nodes={allCollaborations} />
    </>
  )
}

export default BandsPage

export const query = graphql`
  query ($locale: DATO_SiteLocale!) {
    dato {
      ...CommonData
      page: pageBand(locale: $locale) {
        seo: _seoMetaTags {
          ...TagRecordFragment
        }
        slug
        title
      }
      allBands(orderBy: position_ASC) {
        id
        name
        slug: localeSlug
        cover {
          ...ImageFragment
        }
      }
      allConcerts(locale: $locale, orderBy: date_DESC) {
        ...ConcertRecordFragment
      }
      allCollaborations(locale: $locale, orderBy: position_ASC) {
        id
        name
        description
        cover {
          ...ImageFragment
        }
        video {
          ...VideoFragment
        }
      }
    }
  }
`
